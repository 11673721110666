const PageState = {
    View: 0,
    Add: 1,
    Edit: 2,
    ViewDetails: 3,
    Delete: 4,
    Restore: 5,
    Items: 6,
    OfferInvoice: 7,
    AddProviders: 8
};

export default PageState;
