import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import ServiceType from "../../../../Constants/ServiceType";


const ServiceListForm = ({
                                onSubmit,
                                onCancel,
                             type,
                                data,
                                form,
                                errors,
                             onChange,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">

            <TextFieldControl
                name='service_code'
                rules={formRules['service_code']}
                control={data}
                error={Boolean(errors.service_code)}
                helperText={errors.service_code && strings.forms.common.thisFieldIsRequired}
                label={'Šifra'}
            />

            <TextFieldControl
                onChange={onChange}
                name='pdv'
                rules={formRules['pdv']}
                control={data}
                error={Boolean(errors.pdv)}
                helperText={errors.pdv && strings.forms.common.thisFieldIsRequired}
                label={'PDV'}
            />

            <TextFieldControl
                onChange={onChange}
                name='price_without_pdv'
                rules={formRules['price_without_pdv']}
                control={data}
                error={Boolean(errors.price_without_pdv)}
                helperText={errors.price_without_pdv && strings.forms.common.thisFieldIsRequired}
                label={'Cena bez PDV-a'}
            />

            <TextFieldControl
                name='unit_of_measure'
                rules={formRules['unit_of_measure']}
                control={data}
                error={Boolean(errors.unit_of_measure)}
                helperText={errors.unit_of_measure && strings.forms.common.thisFieldIsRequired}
                label={'Jedinica mere'}
            />

            <TextFieldControl
                multiline={true}
                name='description'
                rules={formRules['description']}
                control={data}
                error={Boolean(errors.description)}
                helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                label={'Opis usluge'}
            />
            <SelectControl
                error={Boolean(errors.type)}
                defaultValue={type}
                setValue={setValue}
                name='type'
                rules={formRules['type']}
                label={'Vrsta usluge'}
                options={ServiceType}
                nameKey={'value'}
                valueKey={'id'}
            />


            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default ServiceListForm;