import {createSlice} from "@reduxjs/toolkit";

export const siteDataSlice = createSlice({
    name: 'siteData',
    initialState: {
        language: 'en',
        data: {}
    },
    reducers: {
        updateSiteData: (state, action) => {
            state.data = action.payload
        },
        updateSiteLanguage: (state, action) => {
            state.language = action.payload
        }
    }
});

export const { updateSiteData } = siteDataSlice.actions
export const { updateSiteLanguage } = siteDataSlice.actions

export default siteDataSlice.reducer