import {createSlice} from "@reduxjs/toolkit";
import PageSizeState from "../Constants/Base/PageSizeState";

export const pageSlice = createSlice({
    name: 'page',
    initialState: {
        pageSizeState: PageSizeState.SHORT
    },
    reducers: {
        changePageSizeState: (state, action) => {
            state.pageSizeState = action.payload
        }
    }
});

export const { changePageSizeState } = pageSlice.actions

export default pageSlice.reducer
