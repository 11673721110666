import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import TablePageContext, {FilterDefaults, FilterDefaultsMax} from "../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import {editInvoice, generateInvoicePdf} from "../../Services/Invoice/InvoiceService";
import InvoiceListForm from "../../Components/Forms/Pages/Invoice/InvoiceListForm";
import AddProvidersForm from "../../Components/Forms/Pages/Invoice/AddProvidersForm";
import {getAllUsers} from "../../Services/User/UserService";


const formRules = {
    'maker': {required: true}, 'oversight': {required: true}
}

const AddProviders = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const {setShowSnackbar, setMessageSnackbar, setAlertType} = useContext(TablePageContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const [filter, setFilter] = useState(FilterDefaultsMax);
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [users, setUsers] = useState([])

    const fetch = () => {
        getAllUsers(filter).then(response => {
            setUsers(response.data.result)
        })
    }

    useEffect(() => {
        fetch();
    }, [filter])

    useEffect(() => {
        setDrawerTitle('Specifikacija fakture');
    });

    const showMessage = (message, type) => {
        setAlertType(type);
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }

    const onSubmit = () => {

        setShowLoader(true);
        generateInvoicePdf(props.invoiceId, getValues().maker.id, getValues().oversight.id).then(response => {
            props.onFinish();
        })
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <AddProvidersForm
                selectedItems={props.data}
                users={users}
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddProviders;