import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllCompanyOwners(data) {
    return await request('/api/company_owners/all', data, HttpMethod.GET);
}

export async function editCompanyOwner(data) {
    return await request('/api/company_owners', data, HttpMethod.PUT);
}

export async function addCompanyOwner(data) {
    return await request('/api/company_owners', data, HttpMethod.POST);
}

export async function deleteCompanyOwner(id) {
    return await request('/api/company_owners/' + id, [], HttpMethod.DELETE);
}