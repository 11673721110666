import React from "react";
import {useForm} from "react-hook-form";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import ResetPasswordRequestForm from "../../Components/Forms/Pages/User/ResetPasswordRequestForm";

const onSubmit = (data) => {

}

const ResetPasswordRequest = () => {

    const form = useForm();
    const { data, handleSubmit, formState: { errors } } = form;

    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL));

    return <div id='reset-password-request' className={'full-form-page'}>
        <Grid item md={6}>
            <Paper className='paper'>

                <h1>{ strings.pages.resetPasswordRequest.resetPassword }</h1>

                <ResetPasswordRequestForm errors={ errors } data={ data } form={ form } onSubmit={ handleSubmit(onSubmit) }/>
            </Paper>
        </Grid>
    </div>
};
export default ResetPasswordRequest;
