import React, {useEffect} from "react";
import LoginForm from "../../Components/Forms/Pages/User/LoginForm";
import {useForm} from "react-hook-form";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {login} from "../../Base/OAuth";
import {setAuth} from "../../Slices/AuthSlice";
import {useNavigate} from "react-router-dom";

const formRules = {
    'username': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'password': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useForm();
    const {handleSubmit, formState: {errors}} = form;

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL));
    }, [])

    const onSubmit = (data) => {
        login(data.username, data.password).then(response => {

            if (!response || !response.ok) {
                return;
            }

            dispatch(setAuth(response.data.user));
            navigate('/home');
        });
    }

    return <div id='container' className={'full-form-page'}>
        <div className='image background-image' style={{backgroundImage: "url('/images/login.jpg')"}}>

        </div>
        <div className='form-container'>
            <Paper className='paper'>
                <h1>Prijava</h1>
                <LoginForm formRules={formRules} errors={errors} form={form} onSubmit={handleSubmit(onSubmit)}/>
            </Paper>
        </div>
    </div>
};
export default Login;
