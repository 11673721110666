import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch, useSelector} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import LockForm from "../../Components/Forms/Pages/User/LockForm";
import {login} from "../../Base/OAuth";
import {setAuth} from "../../Slices/AuthSlice";
import {useNavigate} from "react-router-dom";

const Lock = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user)

    const form = useForm();
    const { data, handleSubmit, formState: { errors } } = form;

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL));
    }, [])

    const onSubmit = (data) => {

        login(user.email, data.password).then(response => {

            if(!response || !response.ok) {
                return;
            }

            dispatch(setAuth(response.data.user));
            navigate('/');
        });
    }

    return <div id='lock' className={'full-form-page'}>
        <Grid item md={6}>
            <Paper className='paper'>

                <h1>{ strings.pages.lock.lock }</h1>

                <LockForm errors={ errors } data={ data } form={ form } onSubmit={ handleSubmit(onSubmit) }/>
            </Paper>
        </Grid>
    </div>
};
export default Lock;
