import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllBills(data) {
    return await request('/api/bills/all', data, HttpMethod.GET);
}

export async function deleteBill(id) {
    return await request('/api/bills/' + id, [], HttpMethod.DELETE);
}

export async function generatePdf(id) {
    window.open(process.env.REACT_APP_BASE_API_URL + 'generate_pdf_bill/' + id.id);
}