import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";


const BusinessPartnerListForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">

            <TextFieldControl
                name='name'
                rules={formRules['name']}
                control={data}
                error={Boolean(errors.name)}
                helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                label={'Ime'}
            />

            <TextFieldControl
                name='jbkjs'
                rules={formRules['jbkjs']}
                control={data}
                error={Boolean(errors.jbkjs)}
                helperText={errors.jbkjs && strings.forms.common.thisFieldIsRequired}
                label={'JBKJS'}
            />

            <TextFieldControl
                name='address'
                rules={formRules['address']}
                control={data}
                error={Boolean(errors.address)}
                helperText={errors.address && strings.forms.common.thisFieldIsRequired}
                label={'Adresa'}
            />

            <TextFieldControl
                name='pib'
                rules={formRules['pib']}
                control={data}
                error={Boolean(errors.pib)}
                helperText={errors.pib && strings.forms.common.thisFieldIsRequired}
                label={'PIB'}
            />

            <TextFieldControl
                name='mb'
                rules={formRules['mb']}
                control={data}
                error={Boolean(errors.mb)}
                helperText={errors.mb && strings.forms.common.thisFieldIsRequired}
                label={'MB'}
            />


            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default BusinessPartnerListForm;