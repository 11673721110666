import React from "react";

import {Route, Routes} from "react-router-dom";
import Login from "./Pages/User/Login";
import Lock from "./Pages/User/Lock";
import Home from "./Pages/Home";
import ResetPasswordRequest from "./Pages/User/ResetPasswordRequest";
import {isUserLoggedIn} from "./Base/OAuth";
import UsersList from "./Pages/User/UsersList";
import BusinessYearList from "./Pages/BusinessYear/BusinessYearList";
import ServiceList from "./Pages/Service/ServiceList";
import InvoiceList from "./Pages/Invoice/InvoiceList";
import ChangePassword from "./Pages/User/ChangePassword";
import OfferList from "./Pages/Offer/OfferList";
import OfferItemsList from "./Pages/Offer/OfferItemsList";
import OfferContractList from "./Pages/OfferContract/OfferContractList";
import BusinessPartnerList from "./Pages/BusinessPartner/BusinessPartnerList";
import ProgramPositionList from "./Pages/ProgramPosition/ProgramPositionList";
import InvoiceItemsList from "./Pages/Invoice/InvoiceItemsList";
import CompanyOwnerList from "./Pages/CompanyOwner/CompanyOwnerList";
import BillList from "./Pages/Bill/BillList";
import BillItemList from "./Pages/BillItem/BillItemList";
import AddProviders from "./Pages/Invoice/AddProviders";
import AdditionalInvoiceItemsList from "./Pages/Invoice/AdditionalInvoiceItemsList";

const userPages = {
    Users: {
        path: '/users',
        component: <UsersList/>,
        auth: true,
        exact: true
    },
    Company: {
        path: '/company',
        component: <CompanyOwnerList/>,
        auth: true,
        exact: true
    },
    BillList: {
        path: '/bills',
        component: <BillList/>,
        auth: true,
        exact: true
    },
    BillItemList: {
        path: '/bill-items',
        component: <BillItemList/>,
        auth: true,
        exact: true
    },
    BusinessPartners: {
        path: '/business-partners',
        component: <BusinessPartnerList/>,
        auth: true,
        exact: true
    },
    Offers: {
        path: '/offers',
        component: <OfferList/>,
        auth: true,
        exact: true
    },
    OfferItems: {
        path: '/offer-items',
        component: <OfferItemsList/>,
        auth: true,
        exact: true
    },
    InvoiceItems: {
        path: '/invoice-items',
        component: <InvoiceItemsList/>,
        auth: true,
        exact: true
    },
    AdditionalInvoiceItems: {
        path: '/additional-invoice-items',
        component: <AdditionalInvoiceItemsList/>,
        auth: true,
        exact: true
    },
    Invoices: {
        path: '/invoices',
        component: <InvoiceList/>,
        auth: true,
        exact: true
    },
    OfferContracts: {
        path: '/offer-contracts',
        component: <OfferContractList/>,
        auth: true,
        exact: true
    },
    Services: {
        path: '/services',
        component: <ServiceList/>,
        auth: true,
        exact: true
    },
    BusinessYears: {
        path: '/business-years',
        component: <BusinessYearList/>,
        auth: true,
        exact: true
    },
    ProgramPositions: {
        path: '/program-positions',
        component: <ProgramPositionList/>,
        auth: true,
        exact: true
    },
    Home: {
        path: '/home',
        component: <Home/>,
        auth: false,
        exact: true
    },
    Login: {
        path: '/login',
        component: <Login/>,
        auth: false,
        exact: true
    },
    Lock: {
        path: '/lock',
        component: <Lock/>,
        auth: false,
        exact: true
    },
    ChangePassword: {
        path: '/change-password',
        component: <ChangePassword/>,
        auth: false,
        exact: true
    },
    ResetPasswordRequest: {
        path: '/reset-password-request',
        component: <ResetPasswordRequest/>,
        auth: false,
        exact: true
    }
}

Object.assign(Routes, Routes, userPages);

export function getRoutes() {

    let result = [];

    for (const [key, value] of Object.entries(Routes)) {

        result.push(
            <Route key={'route-' + key} path={value.path} element={value.component}/>
        )
    }

    return <Routes>{result}</Routes>;
}

function getRoute(path) {

    for (const [key, value] of Object.entries(Routes)) {

        if (value.path === path) {
            return value;
        }
    }

    return null;
}

export function checkPath(path) {

    let pathObject = getRoute(path);

    if (!pathObject) {
        return true;
    }

    if (pathObject.auth) {
        return !isUserLoggedIn();
    }

    return false;
}