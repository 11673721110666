import * as React from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from "react";
import {Alert, dialogTitleClasses, Drawer, IconButton, Paper, Snackbar} from "@mui/material";
import TextFieldControl from "../Controls/Inputs/TextFieldControl";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import DrawerWrapper from "../Layout/DrawerWrapper";
import PageState from "../../Constants/Base/PageState";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import TablePageContext from "../../Context/TablePageContext";
import ActionCell from "./ActionCell";
import YesNoDialog, {YesNoDialogResult} from "../Dialogs/YesNoDialog";
import SelectControl from "../Controls/Inputs/SelectControl";
import {getMonths, getYears} from "../../Util/DateUtil";

const TablePage = (props) => {

    const {
        tablePageOptions,
        setSelectedItems,
        selectionModel, setSelectionModel,
        pageState, setPageState, filter, setFilter, showDeleteDialog, setShowDeleteDialog,
        showSnackbar, setShowSnackbar, messageSnackbar, setMessageSnackbar, alertType, setAlertType
    } = useContext(TablePageContext);
    const [drawerTitle, setDrawerTitle] = useState('');
    const rowsPerPageOptions = JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE);

    const form = useForm();
    const {data, watch, setValue} = form;

    let watchValues = ['search', 'year', 'month'];

    if (props.filters) {
        for (let filter of props.filters) {
            watchValues.push(filter.name)
        }
    }

    watch(watchValues);

    const value = {drawerTitle, setDrawerTitle}

    useEffect(() => {
        const subscription = watch((data) => setFilter({
            ...filter,
            ...getFilterValues(data),
            term: data.search,
            year: data.year ? data.year.id : undefined,
            month: data.month ? data.month.id : undefined
        }));
    }, [watch]);

    const getFilterValues = (data) => {

        let result = {}

        for (let filter of props.filters) {
            result = {
                ...result,
                [filter.name]: data[filter.name] ? data[filter.name][filter.valueKey] : undefined
            }
        }

        return result
    }

    const onPageSizeChange = (perPage) => {
        setFilter({
            ...filter,
            perPage: perPage
        });
    }

    const onPageChange = (page) => {
        setFilter({
            ...filter,
            page: page + 1
        });
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    const deleteItem = (id) => {
        setShowDeleteDialog(true)
    }

    const handleAdd = () => {
        if (props.handleAdd) {
            props.handleAdd();
            return;
        }

        setPageState(PageState.Add)
    }

    const handleDeleteDialogResult = (result, payload) => {

        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }

        if (!props.deleteItem || !selectionModel || selectionModel.length === 0) {
            setShowDeleteDialog(false);
            return;
        }

        props.deleteItem(selectionModel[0]).then(response => {
            if (!response || !response.ok) {
                setMessageSnackbar(strings.components.tablePage.errorDeletingItem);
                setAlertType('error')
                setShowSnackbar(true)
                setShowDeleteDialog(false);
                setSelectionModel([]);
                props.onFinish();
                return;
            }

            setShowDeleteDialog(false);
            setSelectionModel([]);

            setTimeout(() => {
                props.onFinish();
                setMessageSnackbar(strings.components.tablePage.itemDeleted);
                setAlertType('success')
                setShowSnackbar(true)
            }, 1000)
        })
    }

    const getTableDescription = (tableDescription) => {

        if (!tableDescription || tableDescription.length === 0) {
            return tableDescription;
        }

        if (props.hideActions){
            return tableDescription;
        }

        let result = tableDescription;
        result.unshift({
            field: 'action',
            headerName: strings.components.tablePage.actions,
            width: 75,
            renderCell: (params) => <ActionCell handleAddItems={() => props.handleAddItems(params)} showAdditionalInvoiceItems={props.showAdditionalInvoiceItems} showGetXmlItems={props.showGetXmlItems} hideEdit={props.hideEdit} showInvoiceItems={props.showInvoiceItems} showPdf={props.showPdf} exportPdf={() => props.exportPdf(params)} handleInvoice={() => props.handleInvoice(params)} showInvoices={props.showInvoices} showContracts={props.showContracts} showItems={props.showItems} showPureItems={props.showPureItems} params tablePageOptions={tablePageOptions}
                                                handleContracts={() => props.handleContracts(params)} handleGetXml={() => props.handleGetXml(params)} handleItems={() => props.handleItems(params)}
                                                handleEdit={() => props.handleEdit(params)}
                                                handleDelete={deleteItem}/>
        });

        return result;
    }

    const getItemById = (id, data) => {
        if (!id || !data) {
            return undefined;
        }

        return data.find(x => x.id === id)
    }

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);

        if (!newSelectionModel || newSelectionModel.length === 0) {
            setSelectedItems([]);
        }

        let result = [];

        for (let id of newSelectionModel) {
            result.push(getItemById(id, props.tableData.data));
        }

        setSelectedItems(result);
    }

    const renderFilters = () => {
        let result = [];

        for (let filter of props.filters) {
            result.push(
                <div className='filter-item'>
                    <SelectControl
                        key={'table-filter-' + result.length}
                        setValue={setValue}
                        name={filter.name}
                        label={filter.label}
                        options={props.filterOptions[filter.optionsName]}
                        nameKey={filter.nameKey}
                        valueKey={filter.valueKey}
                    />
                </div>
            )
        }

        return result;
    }


    return <DrawerContext.Provider value={value}>
        <YesNoDialog show={showDeleteDialog}
                     payload={selectionModel}
                     handleResult={handleDeleteDialogResult}
                     title={strings.components.tablePage.confirmDelete}
                     text={strings.components.tablePage.confirmDeleteMessage}/>
        <div id={'table-page'}>
            <div className={'header'}>
                <div className={'filter-container left-filter'}>
                    <div className={'search-container'}>
                        <FormProvider {...form}>
                            <div style={{display: 'flex'}} className='filter-item'>
                                <TextFieldControl
                                    name='search'
                                    control={data}
                                    defaultValue=''
                                    margin="normal"
                                    label={strings.components.tablePage.search}
                                />
                            </div>
                            {
                                props.totalPrice &&
                                <div style={{width: '300px', fontSize: '16px'}}>
                                    Cena sa PDV-om: {props.totalPrice}
                                </div>
                            }
                            {
                                tablePageOptions.showYearFilter &&
                                <div className='filter-item'>
                                    <SelectControl
                                        setValue={setValue}
                                        name='year'
                                        label={strings.components.tablePage.year}
                                        options={getYears()}
                                        nameKey={'value'}
                                        valueKey={'id'}
                                    />
                                </div>
                            }
                            {
                                tablePageOptions.showMonthFilter &&
                                <div className='filter-item'>
                                    <SelectControl
                                        setValue={setValue}
                                        name='month'
                                        label={strings.components.tablePage.month}
                                        options={getMonths()}
                                        nameKey={'value'}
                                        valueKey={'id'}
                                    />
                                </div>
                            }
                            {
                                props.filters && props.filters.length > 0 &&
                                renderFilters()
                            }
                        </FormProvider>
                    </div>
                </div>
                <div style={{display: props.hideButton ? 'none' : ''}} className={'filter-container right-filter'}>
                    <IconButton onClick={() => handleAdd()}>
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </div>
            </div>
            <Paper>
                <DataGrid columns={getTableDescription(props.tableDescription)} rows={props.tableData.data}
                          autoHeight rowHeight={40}
                          onSelectionModelChange={(newSelectionModel) => handleSelectionChange(newSelectionModel)}
                          selectionModel={selectionModel}
                          paginationMode="server" rowCount={props.tableData.total} loading={props.tableData.loading}
                          onPageSizeChange={(perPage) => onPageSizeChange(perPage)}
                          onPageChange={(page) => onPageChange(page)}
                          pageSize={props.filter.perPage} rowsPerPageOptions={rowsPerPageOptions}
                          components={{
                              Toolbar: GridToolbar,
                          }}
                />
            </Paper>

            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert severity={alertType} onClose={handleCloseSnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            <Drawer id='drawer' className={pageState === PageState.Items ? 'items' : ''} anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle}>
                    {
                        pageState === PageState.Add && props.addPage &&
                        props.addPage
                    }
                    {
                        pageState === PageState.Edit && props.editPage &&
                        props.editPage
                    }
                    {
                        pageState === PageState.OfferInvoice && props.addInvoicePage &&
                        props.addInvoicePage
                    }
                    {
                        pageState === PageState.AddProviders && props.addProviders &&
                        props.addProviders
                    }
                </DrawerWrapper>
            </Drawer>
        </div>
    </DrawerContext.Provider>

}

export default TablePage;