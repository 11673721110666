import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import TablePageContext from "../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import OfferListForm from "../../Components/Forms/Pages/Offer/OfferListForm";
import {editOffer} from "../../Services/Offer/OfferService";
import OfferItemsListForm from "../../Components/Forms/Pages/Offer/OfferItemsListForm";
import {useLocation, useParams} from "react-router-dom";
import {editOfferItem} from "../../Services/Offer/OfferItemService";
import {editInvoiceItem} from "../../Services/Invoice/InvoiceItemService";
import InvoiceItemsListForm from "../../Components/Forms/Pages/Invoice/InvoiceItemsListForm";
import ServiceType from "../../Constants/ServiceType";


const formRules = {
    'service': {required: true}, 'count': {required: true}
}

const EditInvoiceItem = (props) => {
    const location = useLocation();
    const id = location.state ? location.state.id : null;

    const [count, setCount] = useState('');
    const [service, setService] = useState(null);
    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const {setShowSnackbar, setMessageSnackbar, setAlertType} = useContext(TablePageContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setDrawerTitle('Dodaj stavke fakture');
    });

    const showMessage = (message, type) => {
        setAlertType(type);
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        data.date = data.date ? data.date : false;

        editInvoiceItem({
            id : props.data[0].id,
            service : data.service ? data.service.id : null,
            item : data.item ? data.item.id : null,
            count : data.count}).then(response => {

            if (!response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            if (response.data.message && response.data.message !== 'success'){
                props.onFinish();
                showMessage(response.data.message, 'error');
                return;
            }

            showMessage('Uspešno izmenjena stavka', 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <InvoiceItemsListForm
                count={count}
                service={
                    (props.data[0].service.type === ServiceType[0].id) ||
                    (props.data[0].service.type === ServiceType[2].id) ?
                    props.data[0].service :
                    null
                }
                item={props.data[0].service === ServiceType[1].id ? props.data[0].service : null}
                services={props.services}
                items={props.items}
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={props.data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => props.onCancel()}/>
        </Paper>
    </div>
}

export default EditInvoiceItem;