import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllInvoices(data) {
    return await request('/api/invoices/all', data, HttpMethod.GET);
}

export async function editInvoice(data) {
    return await request('/api/invoices', data, HttpMethod.PUT);
}

export async function addInvoice(data) {
    return await request('/api/invoices', data, HttpMethod.POST);
}

export async function deleteInvoice(id) {
    return await request('/api/invoices/' + id, [], HttpMethod.DELETE);
}

export async function getXmlInvoice(id) {
    // window.open(process.env.REACT_APP_BASE_API_URL + '/invoices/get_xml_invoice/' + id);
    return await request('/api/invoices/get_xml_invoice/' + id, [], HttpMethod.GET);
}

export async function generateInvoicePdf(id, maker, oversight) {
    window.open(process.env.REACT_APP_BASE_API_URL + 'invoices/pdf_generate/' + id + '/' + maker + '/' + oversight);
}