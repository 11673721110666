import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import TablePageContext from "../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import ServiceListForm from "../../Components/Forms/Pages/Service/ServiceListForm";
import {editService} from "../../Services/Service/ServiceService";
import {onChangeFormat, onChangeFormatEdit} from "../../Util/RenderDataUtil";


const formRules = {
    'service_code': {required: true}, 'pdv': {required: true}, 'price_without_pdv' : {required : true},
    'price_with_pdv' : {required : true}, 'unit_of_measure' : {required : true}, 'description' : {required : true},
    'type' : {required : true}
}

const EditService = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const {setShowSnackbar, setMessageSnackbar, setAlertType} = useContext(TablePageContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ?
            {
                ...props.data[0],
                pdv : onChangeFormatEdit(props.data[0].pdv),
                price_with_pdv : onChangeFormatEdit(props.data[0].price_with_pdv),
                price_without_pdv : onChangeFormatEdit(props.data[0].price_without_pdv),
            }
            :
            undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setDrawerTitle('Izmeni uslugu');
    });

    const showMessage = (message, type) => {
        setAlertType(type);
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editService(data).then(response => {
            setShowLoader(false);

            if (!response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            setTimeout(()=> {
                showMessage('Uspešno izmenjena usluga', 'success');
                props.onFinish();
            }, 1000)
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    const onFormatNumber = (event) => {
        setValue(event.target.name, onChangeFormat(event.target.value))
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ServiceListForm
                onChange={onFormatNumber}
                type={props.data[0].type}
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditService;