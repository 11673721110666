import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import TablePageContext from "../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import {editUser} from "../../Services/User/UserService";
import UserListForm from "../../Components/Forms/Pages/User/UserListForm";
import {editBusinessYear} from "../../Services/BusinessYear/BusinessYearService";
import BusinessYearListForm from "../../Components/Forms/Pages/BusinessYear/BusinessYearListForm";

const formRules = {
    'year': {required: true}, 'avans': {required: true}, 'current_avans' : {required : true}, 'discount_avans' : {required : true}
}

const EditBusinessYear = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const {setShowSnackbar, setMessageSnackbar, setAlertType} = useContext(TablePageContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setDrawerTitle('Izmeni poslovnu godinu');
    });

    const showMessage = (message, type) => {
        setAlertType(type);
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editBusinessYear(data).then(response => {
            setShowLoader(false);

            if (!response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            if (response.data.message){
                showMessage(response.data.message, 'error');
                return
            }

            setTimeout(()=> {
                showMessage('Uspešno izmenjena godina', 'success');
                props.onFinish();
            }, 1000)
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <BusinessYearListForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditBusinessYear;