import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import strings from "../../localization";

export const YesNoDialogResult = {
    YES: 1,
    NO: 2,
    CANCEL: 3
}

const YesNoDialog = ({payload, handleResult, title, text, show}) => {

    const selected = (result) => {
        if(handleResult) {
            handleResult(result, payload)
        }
    }

    return (
        <Dialog open={ show }
                onClose={ () => selected(YesNoDialogResult.CANCEL) }
                aria-labelledby='draggable-dialog-title'
        >
            <DialogTitle id='draggable-dialog-title'>
                { title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { text }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => selected(YesNoDialogResult.NO) } color='primary' variant="contained">
                    { strings.components.dialogs.yesNoDialog.no }
                </Button>
                <Button onClick={ () => selected(YesNoDialogResult.YES) } color='secondary' variant="contained">
                    { strings.components.dialogs.yesNoDialog.yes }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default YesNoDialog;