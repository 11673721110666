import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider } from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const LoginForm = ({
                       onSubmit,
                       data,
                       form,
                       errors
                   }) => (
    <FormProvider {...form}>
        <form id='lock-form' className='form'  action = "#">

            <TextFieldControl
                name='password'
                type='password'
                control={data}
                defaultValue=''
                rules={{ required: true }}
                fullWidth
                margin="normal"
                error={ Boolean(errors.password) }
                helperText={errors.password && strings.forms.lock.passwordError}
                label={ strings.forms.lock.password }
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    { strings.forms.lock.unlock }
                </Button>
            </div>
        </form>
    </FormProvider>

);

export default LoginForm;
