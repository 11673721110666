import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import {useLocation, useNavigate} from "react-router-dom";
import {deleteOfferContract, getAllOfferContracts} from "../../Services/Offer/OfferContractService";
import EditOfferContract from "./EditOfferContract";
import AddOfferContract from "./AddOfferContract";
import {formatAMPM} from "../../Util/DateUtil";

const tableDescription = [

    { field: 'order_contract', headerName: 'Broj Ugovora naše kompanije', width: 200 },
    { field: 'company_contract', headerName: 'Broj ugovora naručioca', width: 200 },
    { field: 'order_contract_date', headerName: 'Datum ugovora naše kompanije', width: 200,
        renderCell : (params) => formatAMPM(new Date(Date.parse(params.value))) },
    { field: 'company_contract_date', headerName: 'Datum ugovora naručioca', width: 200,
        renderCell : (params) => formatAMPM(new Date(Date.parse(params.value))) }
];

const filters = [];

const OfferContractList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [programPositions, setProgramPositions] = useState([]);
    const [businessYears, setBusinessYears] = useState([]);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [columns, setColumns] = useState([]);
    const id = location.state ? location.state.id : null;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle('Ugovorene ponude'));
        // fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        // setTimeout(()=> {
            fetch();
        // }, 1000)
        setPageState(PageState.View);
    }

    const onFinish = () => {
        setTimeout(()=> {
            fetch();
        }, 1000)
        setPageState(PageState.View);
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const handleItems = (data) => {
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        filter.offerId = id ? id : null;

        getAllOfferContracts({...filter}).then(response => {
            let result = response.data.result;

            setTableData({
                loading: false,
                data: result,
                total: response.data.total
            })
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteOfferContract}
                   tableData={tableData} filter={filter}
                   handleEdit={handleEdit} filters={filters}
                   onCancel={() => onCancel()}
                   handleItems={handleItems}
                   editPage={<EditOfferContract data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddOfferContract onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default OfferContractList;