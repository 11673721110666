import {createContext} from "react";
import PageState from "../Constants/Base/PageState";

const TablePageContext = createContext({
    page: 1,
    pageState: PageState.View,
    showSnackbar: false,
    messageSnackbar: '',
    alertType: 'success',
    setPage: () => {},
    setPageState: () => {},
    setShowSnackbar: () => {},
    setMessageSnackbar: () => {},
    setAlertType: () => {}
});

export default TablePageContext;

export const FilterDefaults = {
    page: 1,
    perPage: parseInt(process.env.REACT_APP_ROWS_PER_PAGE_DEFAULT),
    term: ''
}

export const FilterDefaultsMax = {
    page: 1,
    perPage: 9999,
    term: ''
}

export const TableDataDefaults = {
    data: [],
    total: 0,
    loading: false
};

export const TablePageOptionDefaults = {
    showYearFilter: false,
    showMonthFilter: false,
    showActionEdit: true,
    showActionView: true,
    showActionDelete: true,
}

export const TablePageOptionNoEdit = {
    showYearFilter: false,
    showMonthFilter: false,
    showActionEdit: false,
    showActionView: true,
    showActionDelete: true,
}

export const TablePageOptionNoEditNoDelete = {
    showYearFilter: false,
    showMonthFilter: false,
    showActionEdit: false,
    showActionView: true,
    showActionDelete: false,
}

export const TablePageOptionYearFilter = {
    showYearFilter: true,
    showMonthFilter: false,
    showActionEdit: true,
    showActionView: true,
    showActionDelete: true,
}