import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllBusinessPartners(data) {
    return await request('/api/business_partners/all', data, HttpMethod.GET);
}

export async function editBusinessPartner(data) {
    return await request('/api/business_partners', data, HttpMethod.PUT);
}

export async function addBusinessPartner(data) {
    return await request('/api/business_partners', data, HttpMethod.POST);
}

export async function deleteBusinessPartner(id) {
    return await request('/api/business_partners/' + id, [], HttpMethod.DELETE);
}