import React, {useEffect} from "react";
import {getRoutes} from "./route";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import AuthWrapper from "./Base/AuthWrapper";
import BaseLayout from "./Base/BaseLayout";
import {useDispatch} from "react-redux";
import {setAuth} from "./Slices/AuthSlice";
import {getUserFromLocalStorage} from "./Base/OAuth";
import {updateSiteData} from "./Slices/SiteDataSlice";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";

const theme = createTheme({typography: {
        useNextVariants: true,
    },"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(106, 114, 133, 1)","main":"#333","dark":"rgba(40, 47, 64, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(245, 84, 112, 1)","main":"#f15d24","dark":"rgba(245, 39, 76, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}});


const App = () => {

    const dispatch = useDispatch();
    dispatch(setAuth(getUserFromLocalStorage()));

    const loadSiteData = () => {

        if(window.location.pathname === '/500') {
            return;
        }
    }

    loadSiteData();

    return (
        <Router>
            <AuthWrapper>
                <ThemeProvider theme={theme}>
                    <BaseLayout>
                        {getRoutes()}
                    </BaseLayout>
                </ThemeProvider>
            </AuthWrapper>
        </Router>
    );
}

export default App;
