import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import ServiceType from "../../../../Constants/ServiceType";
import DateTimeControl from "../../../Controls/Inputs/DateTimeControl";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";


const InvoiceItemsListForm = ({
                                onSubmit,
                                onCancel,
                                items,
                                services,
                                item,
                                service,
                                count,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">
            <TextFieldControl
                defaultValue={count}
                name='count'
                rules={formRules['count']}
                control={data}
                setValue={setValue}
                error={Boolean(errors.count)}
                helperText={errors.count && strings.forms.common.thisFieldIsRequired}
                label={'Količina'}
            />
            {
                service &&
                <SelectControl
                    defaultValue={service}
                    control={data}
                    setValue={setValue}
                    name='service'
                    rules={formRules['service']}
                    label={'Usluga'}
                    options={services}
                    idNameKey={'service_code'}
                    nameKey={'description'}
                    valueKey={'id'}
                />
            }
            {
                !service &&
                <SelectControl
                    control={data}
                    setValue={setValue}
                    name='service'
                    rules={formRules['service']}
                    label={'Usluga'}
                    options={services}
                    idNameKey={'service_code'}
                    nameKey={'description'}
                    valueKey={'id'}
                />
            }
            {
                item &&
                <SelectControl
                    defaultValue={item}
                    control={data}
                    setValue={setValue}
                    name='item'
                    rules={formRules['item']}
                    label={'Roba'}
                    options={items}
                    idNameKey={'service_code'}
                    nameKey={'description'}
                    valueKey={'id'}
                />
            }
            {
                !item &&
                <SelectControl
                    control={data}
                    setValue={setValue}
                    name='item'
                    rules={formRules['item']}
                    label={'Roba'}
                    options={items}
                    idNameKey={'service_code'}
                    nameKey={'description'}
                    valueKey={'id'}
                />
            }

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>

    </FormProvider>
);

export default InvoiceItemsListForm;