import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllOffers(data) {
    return await request('/api/offers/all', data, HttpMethod.GET);
}

export async function generateOfferPdf(id, type) {
    window.open(process.env.REACT_APP_BASE_API_URL + 'offers/pdf_generate/' + id);
}

export async function editOffer(data) {
    return await request('/api/offers', data, HttpMethod.PUT);
}

export async function addOffer(data) {
    return await request('/api/offers', data, HttpMethod.POST);
}

export async function deleteOffer(id) {
    return await request('/api/offers/' + id, [], HttpMethod.DELETE);
}