import React from 'react'
import NumberFormat from 'react-number-format';

export function numberWithCommas(x) {
    let first = x.toString().replace('.', ",");
    return first.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


export function onChangeFormat(value){
    if (!value){
        return '';
    }

    let start = value.toString().replaceAll('.', "");
    return start.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".");
}


export function onChangeFormatEdit(value){
    if (!value){
        return '';
    }

    let start = value.toString().replaceAll('.', ",");
    return start.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function renderNumber(value, prefix = '', suffix = '') {

    let number = Number(value);

    if(isNaN(number)) {
        number = 0;
    }

    return <NumberFormat prefix={ prefix } suffix={suffix}
     value={number.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
}

export function getCurrencyNumber(value, currency) {

    return renderNumber(value, currency ? currency.code + ' ' : '');
}

export function getPercentNumber(value) {

    return renderNumber(value, '', '%');
}