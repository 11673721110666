import {createSlice} from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        showLoader: false
    },
    reducers: {
        changeLoaderState: (state, action) => {
            state.showLoader = action.payload
        }
    }
});

export const { changeLoaderState } = loaderSlice.actions

export default loaderSlice.reducer
