
const ServiceType = [
    {
        value : 'Usluga',
        id : 0
    },
    {
        value : 'Roba',
        id : 1
    },
    {
        value : 'Radovi za koje se ukaže potreba',
        id : 2
    },
];

export default ServiceType;