import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllServices(data) {
    return await request('/api/services/all', data, HttpMethod.GET);
}

export async function editService(data) {
    return await request('/api/services', data, HttpMethod.PUT);
}

export async function addService(data) {
    return await request('/api/services', data, HttpMethod.POST);
}

export async function deleteService(id) {
    return await request('/api/services/' + id, [], HttpMethod.DELETE);
}