import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllOfferItems(data) {
    return await request('/api/offer_items/all', data, HttpMethod.GET);
}

export async function editOfferItem(data) {
    return await request('/api/offer_items', data, HttpMethod.PUT);
}

export async function addOfferItem(data) {
    return await request('/api/offer_items', data, HttpMethod.POST);
}

export async function deleteOfferItem(id) {
    return await request('/api/offer_items/' + id, [], HttpMethod.DELETE);
}