import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults, TablePageOptionNoEdit, TablePageOptionNoEditNoDelete
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import {deleteBusinessPartner, getAllBusinessPartners} from "../../Services/BusinessPartner/BusinessPartnerService";
import {getAllProgramPositions} from "../../Services/ProgramPosition/ProgramPositionService";
import {deleteBill, getAllBills} from "../../Services/Bill/BillService";
import {useLocation, useNavigate} from "react-router-dom";
import {deleteBillItem, getAllBillItems} from "../../Services/Bill/BillItemService";


const tableDescription = [

    { field: 'month', headerName: 'Mesec', width: 200 },
    { field: 'count', headerName: 'Ukupno komada', width: 200},
    { field: 'price', headerName: 'Ukupna cena', width: 200},
];

const BillItemList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [filterTwo, setFilterTwo] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [columns, setColumns] = useState([]);
    const [programPositions, setProgramPosition] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionNoEditNoDelete);
    const filters = [];
    const location = useLocation();
    const id = location.state ? location.state.id : null;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle('Obračunski list ' + id));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        setTimeout(()=> {
            fetch();
            setPageState(PageState.View);
        }, 1000)
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const handleItem = (params) => {
        navigate('/bill-items', {
            state : {
                id : params.id
            }
        });
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        filter.id = id;

        getAllBillItems({...filter}).then(response => {
            let data = response.data.result;
            let totalPrice = 0;
            let totalCount = 0;

            data.forEach((item) => {
                totalPrice += item.price;
                totalCount += item.count;
            });

            data.push({
                id : 0,
                month : 'Ukupno',
                price : totalPrice,
                count : totalCount
            });

            setTableData({
                loading: false,
                data: data,
                total: response.data.total
            })
        })
    }

    return <TablePageContext.Provider value={value}>

        <div className={'bill-item-list'}>
            <TablePage hideActions={true} hideButton={true} hideEdit={true} onFinish={() => onFinish()}  tableDescription={columns}
                       deleteItem={deleteBillItem}
                       tableData={tableData} filter={filter}
                       filters={filters}
                       onCancel={() => onCancel()}
            />
        </div>
    </TablePageContext.Provider>;
}

export default BillItemList;