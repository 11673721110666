import React, {useState} from "react";
import MenuState from "../../Constants/Base/MenuState";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import strings from "../../localization";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MoreVert from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import {useDispatch, useSelector} from "react-redux";
import {changeMenuState} from "../../Slices/NavigationSlice";
import {useNavigate} from "react-router-dom";
import {clearUserData, clearUserDataLock} from "../../Base/OAuth";

const getHeaderClass = (menuState) => {

    if(menuState === MenuState.SHORT) {
        return 'short';
    }

    return '';
}

const Header = () => {

    const menuState = useSelector((state) => state.navigation.menuState)
    const headerTitle = useSelector((state) => state.header.title)
    const dispatch = useDispatch();
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = event => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const logout = () => {
        handleClose();
        clearUserData();
        navigate('/login');
    }

    const changePassword = () => {
        handleClose();
        navigate('/change-password');
    }

    const lock = () => {
        handleClose();
        clearUserDataLock();
        navigate('/lock');
    }

    return <div id='header' className={  getHeaderClass(menuState) }>

        <div className='left'>

            {
                menuState === MenuState.FULL &&
                <IconButton size='small' onClick={ () => dispatch(changeMenuState(MenuState.SHORT)) }>
                    <MoreVert/>
                </IconButton>
            }

            {
                menuState === MenuState.SHORT &&
                <IconButton size='small' onClick={ () => dispatch(changeMenuState(MenuState.FULL)) }>
                    <MenuIcon/>
                </IconButton>
            }
            <h2>{headerTitle}</h2>
        </div>
        <div className='right'>
            <IconButton
                size='small'
                aria-haspopup="true"
                onClick={ (event) =>  handleClick(event) }
            >
                <PersonIcon/>
            </IconButton>
            <Menu
                id='person-menu'
                anchorEl={profileMenuAnchorEl}
                open={ Boolean(profileMenuAnchorEl) }
                onClose={ () => handleClose() }
            >
                <MenuItem onClick={ () => logout() }>
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={ strings.header.logout }/>
                </MenuItem>
                <MenuItem onClick={ () => changePassword() }>
                    <VpnKeyIcon>
                        <ExitToAppIcon/>
                    </VpnKeyIcon>
                    <ListItemText inset primary={ strings.header.changePassword }/>
                </MenuItem>
            </Menu>
        </div>
    </div>
};
export default Header;
