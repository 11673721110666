import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllBusinessYears(data) {
    return await request('/api/business_year/all', data, HttpMethod.GET);
}

export async function editBusinessYear(data) {
    return await request('/api/business_year', data, HttpMethod.PUT);
}

export async function addBusinessYear(data) {
    return await request('/api/business_year', data, HttpMethod.POST);
}

export async function deleteBusinessYear(id) {
    return await request('/api/business_year/' + id, [], HttpMethod.DELETE);
}