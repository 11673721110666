import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import DateTimeControl from "../../../Controls/Inputs/DateTimeControl";
import {TextField} from "@mui/material";
import {DateTimePicker} from "@mui/lab";
import DateControl from "../../../Controls/Inputs/DateControl";


const OfferContractListForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#" style={{
            display : 'flex',
            flexDirection : 'column'
        }}>


            <TextFieldControl
                name='company_contract'
                rules={formRules['company_contract']}
                control={data}
                error={Boolean(errors.company_contract)}
                helperText={errors.company_contract && strings.forms.common.thisFieldIsRequired}
                label={'Broj Ugovora naše kompanije'}
            />

            <TextFieldControl
                name='order_contract'
                rules={formRules['order_contract']}
                control={data}
                error={Boolean(errors.order_contract)}
                helperText={errors.order_contract && strings.forms.common.thisFieldIsRequired}
                label={'Broj ugovora naručioca'}
            />
            <DateControl
                data={data}
                label={'Datum ugovora naše kompanije'}
                name={'company_contract_date'}
                value={values['company_contract_date']}
                setValue={setValue}
            />

            <DateControl
                data={data}
                label={'Datum ugovora naručioca'}
                name={'order_contract_date'}
                value={values['order_contract_date']}
                setValue={setValue}
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default OfferContractListForm;