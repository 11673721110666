import {MenuItem} from "@mui/material";

export const getDropdownOptions = (array, label, label2 = null) => {
    if(!array){
        return;
    }

    let result = [];

    for(let item of array) {
        result.push(<MenuItem key={'menu-option-' + label + '-' + result.length} value={item.id}>{item[label2] ?
            item[label2] + ' ' + item[label] :
            item[label]
        }</MenuItem>)
    }

    return result;
}
