import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import ServiceType from "../../../../Constants/ServiceType";
import DateTimeControl from "../../../Controls/Inputs/DateTimeControl";


const OfferListForm = ({
                                onSubmit,
                                onCancel,
                           programPosition,
                           programPositions,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">


            <TextFieldControl
                name='offer_number'
                rules={formRules['offer_number']}
                control={data}
                error={Boolean(errors.offer_number)}
                helperText={errors.offer_number && strings.forms.common.thisFieldIsRequired}
                label={'Redni broj ponude'}
            />

            <TextFieldControl
                name='name'
                rules={formRules['name']}
                control={data}
                error={Boolean(errors.name)}
                helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                label={'Naziv Ponude'}
            />

            <DateTimeControl
                data={data}
                label={'Datum'}
                name={'date'}
                value={values['date']}
                setValue={setValue}
            />

            <SelectControl
                error={Boolean(errors.program_position)}
                defaultValue={programPosition}
                setValue={setValue}
                name='program_position'
                rules={formRules['program_position']}
                label={'Programska Pozicija'}
                options={programPositions}
                nameKey={'name'}
                valueKey={'id'}
            />


            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default OfferListForm;