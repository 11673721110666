import React, {useEffect, useState} from "react";
import {Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import MenuState from "../../Constants/Base/MenuState";
import {useSelector} from "react-redux";
import strings from "../../localization";
import {Icon} from 'react-icons-kit'
import {dashboard} from 'react-icons-kit/fa/dashboard'
import DateRangeIcon from '@mui/icons-material/DateRange';
import {AccountCircle, ExpandLess, ExpandMore} from "@mui/icons-material";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {getUserFromLocalStorage} from "../../Base/HTTP";
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';

const getNavigationClass = (state) => {

    if (state === MenuState.SHORT) {
        return 'navigation-content-container short';
    }

    return 'navigation-content-container'
}

const Navigation = () => {

    const user = getUserFromLocalStorage();
    const [submenu, setSubmenu] = useState({})
    const [admin, setAdmin] = useState(user ? user.admin : false)
    const menuState = useSelector((state) => state.navigation.menuState)
    const auth = useSelector((state) => state.auth)
    const location = useLocation();

    const isCurrentPath = (path) => {
        return location.pathname === path;
    }
    
    const handleSubmenu = (name) => {
        setSubmenu({
            ...submenu,
            [name]: !submenu[name]
        });
    }

    useEffect(() => {
        setAdmin(user ? user.admin : false)
    })


    const getMenuItems = () => {
        return (
            <React.Fragment>
                {
                    admin &&
                    <List>
                        <Link to={'/users'} className={isCurrentPath('/users') ? 'navigation-link active' : 'navigation-link'}>
                            <ListItem className='navigation-item'>
                                <AccountCircle className='navigation-icon'>
                                    <Icon size={20} icon={dashboard}/>
                                </AccountCircle>
                                <ListItemText inset primary={strings.navigation.users} className='navigation-text'/>
                            </ListItem>
                        </Link>
                    </List>
                }
                <List>
                    <Link to={{
                        pathname : '/invoices',
                        state : {id : null, name : null}
                    }} className={isCurrentPath('/invoices') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <ReceiptIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </ReceiptIcon>
                            <ListItemText inset primary={strings.navigation.invoices} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={{
                        pathname : '/business-partners'
                    }} className={isCurrentPath('/business-partners') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <BusinessCenterIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </BusinessCenterIcon>
                            <ListItemText inset primary={strings.navigation.businessPartner} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={'/services'} className={isCurrentPath('/services') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <ListAltIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </ListAltIcon>
                            <ListItemText inset primary={strings.navigation.services} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={'/business-years'} className={isCurrentPath('/business-years') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <DateRangeIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </DateRangeIcon>
                            <ListItemText inset primary={strings.navigation.businessYear} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={'/program-positions'} className={isCurrentPath('/program-positions') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <PictureInPictureIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </PictureInPictureIcon>
                            <ListItemText inset primary={strings.navigation.programPosition} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={'/offers'} className={isCurrentPath('/offers') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <LocalOfferIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </LocalOfferIcon>
                            <ListItemText inset primary={strings.navigation.offers} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={'/company'} className={isCurrentPath('/company') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <BusinessIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </BusinessIcon>
                            <ListItemText inset primary={strings.navigation.company} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={'/bills'} className={isCurrentPath('/bills') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item'>
                            <FeaturedPlayListIcon className='navigation-icon'>
                                <Icon size={20} icon={dashboard}/>
                            </FeaturedPlayListIcon>
                            <ListItemText inset primary={strings.navigation.bill} className='navigation-text'/>
                        </ListItem>
                    </Link>
                </List>

            </React.Fragment>
        );
    }

    return (
        <Drawer variant="permanent" id='navigation'>
            <div className={getNavigationClass(menuState)}>
                <div className='logo-container'>
                    <Link to={'/'}>
                        <img src={'images/logo.png'}/>
                    </Link>
                </div>
                <List component="nav">
                    {getMenuItems()}
                </List>
            </div>
        </Drawer>
    );
};
export default Navigation;
