import {Controller} from "react-hook-form";
import React from "react";
import {Box, FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import {getDropdownOptions} from "../../../Util/DropdownUtil";

const SelectControl = (props) => {

    const getValue = (value) => {
        if(!props.valueKey || !props.options) {
            return value;
        }

        return  props.options.find(x => x[props.valueKey] === value)
    }

    const getSelect = (props) => {
        if(props.useValue) {
            return <Select
                onChange={e => {
                    props.setValue(props.name, getValue(e.target.value));
                }}
                defaultValue={props.valueKey && props.defaultValue ? props.defaultValue[props.valueKey] : -1}
                value={props.valueKey && props.value ? props.value[props.valueKey] : -1}
                label={props.label}
                size={props.size ? props.size : 'small'}
                error={props.error}
            >
                {getDropdownOptions(props.options, props.nameKey)}
            </Select>
        }

        return <Select
            onChange={e => props.setValue(props.name, getValue(e.target.value))}
            defaultValue={props.valueKey && props.defaultValue ? props.defaultValue[props.valueKey] : -1}
            label={props.label}
            size={props.size ? props.size : 'small'}
            error={props.error}
        >
            {getDropdownOptions(props.options, props.nameKey, props.idNameKey)}
        </Select>
    }

    return <Box sx={{ minWidth: 120 }} className={'select-box'}>
        <FormControl fullWidth>
            <InputLabel style={{color: props.error ? 'red' : '' }} id="demo-simple-select-label">{props.label}</InputLabel>
            <Controller
                name={props.name}
                control={props.data}
                rules={props.rules}
                render={({ field }) =>
                    getSelect(props)
                }
            />
            {
                props.error &&
                <FormHelperText>{props.helperText}</FormHelperText>
            }
        </FormControl>
    </Box>

}

export default SelectControl;