import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getInvoiceItems(data) {
    return await request('/api/invoice_items', data, HttpMethod.GET);
}

export async function getAdditionalInvoiceItems(data) {
    return await request('/api/additional_invoice_item', data, HttpMethod.GET);
}

export async function editInvoiceItem(data) {
    return await request('/api/invoice_items', data, HttpMethod.PUT);
}

export async function editAdditionalInvoiceItem(data) {
    return await request('/api/additional_invoice_items', data, HttpMethod.PUT);
}

export async function addInvoiceItem(data) {
    return await request('/api/invoice_items', data, HttpMethod.POST);
}

export async function deleteInvoiceItem(id) {
    return await request('/api/invoice_items/' + id, [], HttpMethod.DELETE);
}

export async function deleteAdditionalInvoiceItem(id) {
    return await request('/api/additional_invoice_item_delete/' + id, [], HttpMethod.DELETE);
}