import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults, TablePageOptionYearFilter
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import EditInvoice from "./EditInvoice";
import AddInvoice from "./AddInvoice";
import {deleteInvoice, generateInvoicePdf, getAllInvoices, getXmlInvoice} from "../../Services/Invoice/InvoiceService";
import {useLocation, useNavigate} from "react-router-dom";
import {getAllProgramPositions} from "../../Services/ProgramPosition/ProgramPositionService";
import {formatAMPM} from "../../Util/DateUtil";
import {generatePdf} from "../../Services/Offer/OfferService";
import {numberWithCommas, renderNumber} from "../../Util/RenderDataUtil";
import AddProviders from "./AddProviders";


const tableDescription = [
    { field: 'invoice_number', headerName: 'Broj fakture', width: 200 },
    { field: 'program_position', headerName: 'Programske pozicije', width: 200,
        renderCell : (params) => renderProgramPosition(params)
    },
    { field: 'start_period', headerName: 'Datum početka prometa usluga', width: 200,
        renderCell : (params) => formatAMPM(new Date(Date.parse(params.value))) },
    { field: 'end_period', headerName: 'Datum završetka prometa usluga', width: 200,
        renderCell : (params) => formatAMPM(new Date(Date.parse(params.value))) },
    { field: 'invoice_date', headerName: 'Datum izdavanja fakture', width: 200,
        renderCell : (params) => formatAMPM(new Date(Date.parse(params.value))) },
    { field: 'pdv', headerName: 'Pdv', width: 200,
        renderCell : (params) => renderPdv(params) },
    { field: 'price_without_pdv', headerName: 'Iznos bez Pdv', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_without_pdv.toFixed(2))},
    { field: 'price_with_pdv', headerName: 'Iznos sa Pdv', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_with_pdv.toFixed(2))},
    { field: 'avans', headerName: 'Avans', width: 200,
        renderCell : (params) => numberWithCommas(params.row.avans.toFixed(2)) },
    { field: 'price_for_pay', headerName: 'Ukupno za naplatu', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_for_pay.toFixed(2)) },
    { field: 'offer', headerName: 'Ponuda', width: 200,
        renderCell : (params) => renderOffer(params)
    }
];

function renderPdv(params){
    return numberWithCommas((params.row.price_with_pdv - params.row.price_without_pdv).toFixed(2));
}

function renderProgramPosition(params){
    return params.row.program_position.name;
}

function renderOffer(params){
    if (!params.row.offer){
        return ''
    }

    return params.row.offer.name;
}

const filters = [];

const InvoiceList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState(location.state ? location.state.name : null);
    const [id, setId] = useState(location.state ? location.state.id : null);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [filterProgram, setFilterProgram] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [programPosition, setProgramPosition] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionYearFilter);
    const [columns, setColumns] = useState([]);
    const [invoiceId, setInvoiceId] = useState(-1);

    if (!location.state && (name || id)){
        setName(null)
        setId(null)

        filter.id = null;

        getAllInvoices({...filter}).then(response => {
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            })
        })
    }

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }
    if (!name){
        // dispatch(changeHeaderTitle(strings.pages.invoice.list.pageTitle));
    }
    useEffect(() => {
        setColumns([...tableDescription]);

        dispatch(changePageSizeState(PageSizeState.SHORT));

        if (!name){
            dispatch(changeHeaderTitle(strings.pages.invoice.list.pageTitle));
        }else {
            dispatch(changeHeaderTitle(strings.pages.invoice.list.pageTitle + ' za ponudu ' + name));
        }
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        setTimeout(()=> {
            fetch();
            setPageState(PageState.View);
        })
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const handleAddItems = (params) => {
        navigate('/additional-invoice-items', {
            state : {
                id : params.id
            }
        });
    }

    const handleGetXml = (params) => {
        getXmlInvoice(params.id).then(response => {
            if (!response.ok){
                return;
            }

            let blob = new Blob([response.data], {type: 'text/xml'});
            let url = URL.createObjectURL(blob);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            a.href = url;
            a.download = params.row.invoice_number;
            a.click();
            window.URL.revokeObjectURL(url);
        })
    }

    function download(url) {
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        if (id) {
            filter.id = id
        }

        getAllInvoices({...filter}).then(response => {
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            })
        })

        getAllProgramPositions({...filterProgram}).then(response => {
            setProgramPosition(
                response.data.result
            )
        })
    }

    const handleItems = (params) => {
        navigate('/invoice-items', {
            state : {
                id : params.id
            }
        });
    }

    const exportPdf = (params) => {
        setInvoiceId(params.id)
        setPageState(PageState.AddProviders);
    }

    return <TablePageContext.Provider value={value}>

        <TablePage showGetXmlItems={true} handleGetXml={handleGetXml} handleAddItems={handleAddItems} showAdditionalInvoiceItems={true} exportPdf={exportPdf} handleItems={handleItems} showPdf={true} showInvoiceItems={true} onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteInvoice}
                   tableData={tableData} filter={filter}
                   handleEdit={handleEdit} filters={filters}
                   onCancel={() => onCancel()}
                   addProviders={<AddProviders invoiceId={invoiceId} data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   editPage={<EditInvoice programPositions={programPosition} data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddInvoice programPositions={programPosition} onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default InvoiceList;