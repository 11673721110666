import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const ResetPasswordRequestForm = ({
                       onSubmit,
                       errors,
                       data,
                       form,
                   }) => (
    <FormProvider {...form}>
        <form id="reset-password-request-form" className='form' onSubmit={ onSubmit } action = "#">

            <TextFieldControl
                name='email'
                control={data}
                defaultValue=''
                rules={{ required: true }}
                fullWidth
                margin="normal"
                error={ Boolean(errors.email) }
                helperText={errors.email && strings.forms.resetPasswordRequest.emailError}
                label={ strings.forms.resetPasswordRequest.email }
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.forms.resetPasswordRequest.resetPassword }
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default ResetPasswordRequestForm;
