import {createSlice} from "@reduxjs/toolkit";

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        title: ''
    },
    reducers: {
        changeHeaderTitle: (state, action) => {
            state.title = action.payload
        }
    }
});

export const { changeHeaderTitle } = headerSlice.actions

export default headerSlice.reducer
