import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import {deleteUser, getAllUsers} from "../../Services/User/UserService";
import {deleteService, getAllServices} from "../../Services/Service/ServiceService";
import EditService from "./EditService";
import AddService from "./AddService";
import ServiceType from "../../Constants/ServiceType";
import {numberWithCommas} from "../../Util/RenderDataUtil";


function renderTableType(params){
    if (params.value.id === 0){
        return 'Usluga';
    }else if (params.value.id === 1){
        return 'Roba';
    }else if (params.value.id === 2){
        return 'Radovi za koje se ukaže potreba';
    }
}

function renderType(value){
    if (value === 0){
        return ServiceType[0];
    }else if (value === 1){
        return ServiceType[1];
    }else if (value === 2){
        return ServiceType[2];
    }
}

function renderServiceField(params){
    return params.value.description;
}

const tableDescription = [

    { field: 'service_code', headerName: 'Šifra', width: 200 },
    { field: 'description', headerName: 'Opis usluge', width: 200,
        renderCell : (params) => renderServiceField(params)  },
    { field: 'unit_of_measure', headerName: 'Jedinica mere', width: 200 },
    { field: 'pdv', headerName: 'PDV', width: 200,
        renderCell : (params) => numberWithCommas(params.row.pdv.toFixed(2)) },
    { field: 'price_without_pdv', headerName: 'Cena bez PDV-a', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_without_pdv.toFixed(2)) },
    { field: 'price_with_pdv', headerName: 'Cena sa PDV-om', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_with_pdv.toFixed(2)) },
    { field: 'type', headerName: 'Vrsta usluge', width: 200 ,
        renderCell: (params) => renderTableType(params)}
];

const ServiceList = () => {

    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const filters = [];
    const [columns, setColumns] = useState([]);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.service.serviceList.pageTitle));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        getAllServices({...filter}).then(response => {
            let result = response.data.result;

            result.forEach(item => {
                item.type = renderType(item.type)
            })

            setTableData({
                loading: false,
                data: result,
                total: response.data.total
            })
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteService}
                   tableData={tableData} filter={filter}
                   handleEdit={handleEdit} filters={filters}
                   onCancel={() => onCancel()}
                   editPage={<EditService data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddService onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default ServiceList;