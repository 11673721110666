import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({

    en: {
        header: {
            lock: 'Lock',
            logout: 'Izloguj se',
            changePassword: 'Promena Passworda',
        },
        constants: {
            finance: {
                invoiceStatus: {
                    created: 'Created',
                    paid: 'Paid',
                    partialPaid: 'Partial paid',
                    overdue: 'Overdue',
                    overpaid: 'Overpaid',
                    all: 'ProgramPosition status'
                },
                invoiceType: {
                    invoice: 'ProgramPosition',
                    all: 'ProgramPosition type'
                },
            },
            hr: {
                organisationUserContractType: {
                    CONTRACT: 'Contract',
                    ANNEX: 'Annex',
                    BONUS: 'Bonus',
                    TRANSPORTATION_COSTS: 'Transportation Costs',
                    BUSINESS_TRIP: 'Business Trip',
                },
                jobPaymentFrequency: {
                    MONTHLY: 'Monthly',
                    WEEKLY: 'Weekly',
                    DAILY: 'Daily'
                }
            }
        },
        pages: {
            invoice : {
                list : {
                    pageTitle : 'Fakture'
                }
            },
            user : {
                userList : {
                    pageTitle: 'Korisnici'
                }
            },
            offer : {
                pageTitle: 'Ponude'
            },
            offerItems : {
                pageTitle: 'Stavke ponude'
            },
            service : {
                serviceList : {
                    pageTitle: 'Usluge'
                }
            },
            businessYear : {
                businessYearList : {
                    pageTitle: 'Poslovna godina'
                }
            },
            changePassword : {
                pageTitle: 'Promena Password-a'
            },
            programPosition : {
                programPositionList : {
                    pageTitle: 'Poslovna godina'
                }
            }

        },
        forms: {
            lock: {
                password: 'Password',
                unlock: 'Unlock'
            },
            login: {
                email: 'Email',
                username: 'Korisničko Ime',
                password: 'Šifra',
                admin: 'Admin',
                name: 'Ime',
                login: 'Prijava',
                usernameError: 'Please enter valid username',
                nameError: 'Please enter valid name',
                passwordError: 'Please enter valid password'
            },
            resetPassword: {
                password: 'Password',
                oldPassword: 'Stari Password',
                passwordRepeat: 'Password repeat',
                newPassword: 'Novi Password',
                resetPassword: 'Promeni password',
                passwordRepeatError: 'Password do not match',
                passwordError: 'Field required'
            },
            changePassword: {
                changePassword: 'Change Password',
            },
            resetPasswordRequest: {
                email: 'Email',
                resetPassword: 'Reset password',
                resetPasswordRequest: 'Email is required'
            },
            searchForm: {
                search: 'Search'
            },
            common: {
                save: 'Sačuvaj',
                cancel: 'Otkaži',
                thisFieldIsRequired: 'This field is required',
                emailFormatError: 'Please enter correct email format'
            },
        },
        navigation: {
            bill: 'Obračunski list',
            users: 'Korisnici',
            services: 'Usluge',
            invoices: 'Fakture',
            offers: 'Ponude',
            company: 'Preduzeće',
            businessYear: 'Poslovna Godina',
            businessPartner: 'Biznis Partner',
            programPosition: 'Programska pozicija'
        },
        components: {
            dialogs: {
                yesNoDialog: {
                    no: 'No',
                    yes: 'Yes'
                }
            },
            tablePage: {
                actions: 'Akcije',
                edit: 'Izmeni',
                view: 'View',
                delete: 'Izbriši',
                restore: 'Restore',
                search: 'Pretraga',
                confirmDelete: 'Confirm delete',
                confirmDeleteMessage: 'Are you sure you want to delete this record?',
                errorDeletingItem: 'Error deleting item',
                itemDeleted: 'Red izbrisan',
                year: 'Year',
                month: 'Month'
            },
            actionCell: {
                view: 'View',
                edit: 'Izmeni',
                delete: 'Izbriši',
            }
        },
        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December'
        },
        commonMessages: {
            addingSuccessful: 'Adding successful',
            errorAdding: 'Error adding',
            editingSuccessful: 'Editing successful',
            errorEditing: 'Error editing',
            deleteSuccessful: 'Delete successful',
            errorDeleting: 'Error deleting'
        },
        common: {
            upload: "Upload",
            save: 'Save',
            cancel: 'Cancel',
            view: 'View',
            download: 'Download',
            viewIno: 'View (ino)',
            downloadIno: 'Download (ino)',
            year: 'Year',
            new: 'New',
            days: 'Days'
        },
    }
});

export default strings;
