import React, {Component} from 'react'

import {Grid, IconButton} from "@mui/material";
import  ChevronRight from "@mui/icons-material/ChevronRight"

const DrawerWrapper = (props) => {

    const { children } = props;

    return <Grid className='drawer-wrapper'>
        <div className='drawer-wrapper-header'>
            <IconButton onClick={ () => props.onBack() }>
                <ChevronRight/>
            </IconButton>
            <h2>{props.title}</h2>
        </div>
        <div className='drawer-wrapper-content'>
            { children }
        </div>
    </Grid>
}

export default DrawerWrapper;
