import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import ServiceType from "../../../../Constants/ServiceType";
import DateTimeControl from "../../../Controls/Inputs/DateTimeControl";


const CreateInvoiceFromOfferForm = ({
                                onSubmit,
                                onCancel,
                           programPosition,
                           programPositions,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">


            <TextFieldControl
                name='invoice_number'
                rules={formRules['invoice_number']}
                control={data}
                error={Boolean(errors.invoice_number)}
                helperText={errors.invoice_number && strings.forms.common.thisFieldIsRequired}
                label={'Šifra fakture'}
            />

            <DateTimeControl
                data={data}
                label={'Datum izdavanja računa'}
                name={'invoice_date'}
                value={values['invoice_date']}
                setValue={setValue}
            />

            <DateTimeControl
                data={data}
                label={'Datum početka prometa usluga'}
                name={'start_period'}
                value={values['start_period']}
                setValue={setValue}
            />

            <DateTimeControl
                data={data}
                label={'Datum završetka prometa usluga'}
                name={'end_period'}
                value={values['end_period']}
                setValue={setValue}
            />

            <TextFieldControl
                name='avans'
                rules={formRules['avans']}
                control={data}
                error={Boolean(errors.avans)}
                helperText={errors.avans && strings.forms.common.thisFieldIsRequired}
                label={'Avans'}
            />

            <DateTimeControl
                data={data}
                label={'Datum avansa'}
                name={'avans_date'}
                value={values['avans_date']}
                setValue={setValue}
            />

            <TextFieldControl
                name='avans_bill'
                rules={formRules['avans_bill']}
                control={data}
                error={Boolean(errors.avans_bill)}
                helperText={errors.avans_bill && strings.forms.common.thisFieldIsRequired}
                label={'Broj računa avansa'}
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default CreateInvoiceFromOfferForm;