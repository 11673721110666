import React from 'react';
import MenuState from "../Constants/Base/MenuState";
import Navigation from "../Components/Layout/Navigation";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import {useSelector} from "react-redux";
import PageSizeState from "../Constants/Base/PageSizeState";

const getContentClass = (state, pageSizeState) => {

    if(pageSizeState === PageSizeState.FULL) {
        return 'content-container short';
    }

    if(state === MenuState.SHORT) {
        return 'content-container short';
    }

    return 'content-container';
}

const getMainContainerClass = (state) => {

    if(state === PageSizeState.SHORT) {
        return 'short';
    }

    return 'full';
}

const BaseLayout = (props) => {

    const menuState = useSelector((state) => state.navigation.menuState)
    const pageSizeState = useSelector((state) => state.page.pageSizeState)

    return <React.Fragment>

        <div id='main-container' className={ getMainContainerClass(pageSizeState) }>
            <div className='navigation-container'>
                <Navigation/>
            </div>
            <div className={ getContentClass(menuState, pageSizeState) }>
                <div className={'header-container'}>
                    <Header/>
                </div>
                { props.children }
                <div className={'footer-container'}>
                    <Footer/>
                </div>
            </div>
        </div>

    </React.Fragment>
};

export default BaseLayout;
