import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Alert, Grid, Paper, Snackbar} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import ChangePasswordForm from "../../Components/Forms/Pages/User/ChangePasswordForm";
import {changePassword} from "../../Services/User/UserService";
import {useNavigate} from "react-router-dom";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";

const ChangePassword = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showMessage = (message, type) => {
        setAlertType(type);
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }
    const [alertType, setAlertType] = useState('success');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const form = useForm();
    const { data, handleSubmit, formState: { errors } } = form;

    dispatch(changeHeaderTitle(strings.pages.changePassword.pageTitle));

    const onSubmit = (data) => {
        changePassword(data).then(response => {
            if (!response.ok){
                showMessage('Neispravan stari password', 'error');
                return;
            }

            showMessage('Uspešno izmenjen password', 'success');

            setTimeout(() => {
                navigate('/')
            }, 1000)
        })
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.changePassword.pageTitle));
    }, [])

    return <div id='reset-password' className={'full-form-page'}>

        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <Alert severity={alertType} onClose={handleCloseSnackbar}>
                {messageSnackbar}
            </Alert>
        </Snackbar>
        <Grid item md={6}>
            <Paper className='paper'>

                <h1>{ strings.forms.resetPassword.resetPassword }</h1>

                <ChangePasswordForm errors={ errors } data={ data } form={ form } onSubmit={ handleSubmit(onSubmit) }/>
            </Paper>
        </Grid>
    </div>
};
export default ChangePassword;
