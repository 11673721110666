import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const ChangePasswordForm = ({
                              onSubmit,
                              errors,
                              data,
                              form,
                          }) => (
    <FormProvider {...form}>
        <form id='reset-password-form' className='form' onSubmit={ onSubmit } action = "#">

            <TextFieldControl
                name='oldPassword'
                control={data}
                defaultValue=''
                rules={{ required: true }}
                fullWidth
                type='password'
                margin="normal"
                error={ Boolean(errors.oldPassword) }
                helperText={errors.oldPassword && strings.forms.resetPassword.passwordError}
                label={ strings.forms.resetPassword.oldPassword }
            />

            <TextFieldControl
                name='newPassword'
                control={data}
                defaultValue=''
                rules={{ required: true }}
                fullWidth
                type='password'
                margin="normal"
                error={ Boolean(errors.newPassword) }
                helperText={errors.newPassword && strings.forms.resetPassword.passwordError}
                label={ strings.forms.resetPassword.newPassword }
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.forms.resetPassword.resetPassword }
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default ChangePasswordForm;
