import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";
import SelectControl from "../../../Controls/Inputs/SelectControl";


const BillListForm = ({
                            partner,
                            programPositions,
                            onSubmit,
                            onCancel,
                            data,
                            form,
                            errors,
                            values,
                            setValue,
                            formRules,
                        }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">

            <TextFieldControl
                name='service_code'
                rules={formRules['service_code']}
                control={data}
                error={Boolean(errors.service_code)}
                helperText={errors.service_code && strings.forms.common.thisFieldIsRequired}
                label={'Šifra stavke'}
            />

            <TextFieldControl
                name='year'
                rules={formRules['year']}
                control={data}
                error={Boolean(errors.year)}
                helperText={errors.year && strings.forms.common.thisFieldIsRequired}
                label={'Godina'}
            />

            <SelectControl
                defaultValue={partner}
                setValue={setValue}
                name='program_position'
                error={Boolean(errors.program_position)}
                rules={formRules['program_position']}
                label={'Programske pozicije'}
                options={programPositions}
                nameKey={'name'}
                valueKey={'id'}
            />


            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default BillListForm;