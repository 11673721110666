import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import {deleteUser, getAllUsers} from "../../Services/User/UserService";
import {deleteBusinessYear, getAllBusinessYears} from "../../Services/BusinessYear/BusinessYearService";
import EditBusinessYear from "./EditBusinessYear";
import AddBusinessYear from "./AddBusinessYear";
import {numberWithCommas} from "../../Util/RenderDataUtil";


const tableDescription = [

    { field: 'year', headerName: 'Godina', width: 200 },
    // { field: 'discount_avans', headerName: 'Popust Avans', width: 200 },
    // { field: 'current_avans', headerName: 'Trenutni Avans', width: 200 },
    { field: 'avans', headerName: 'Avans', width: 200,
        renderCell : (params) => numberWithCommas(params.row.avans.toFixed(2)) }
];

const BusinessYearList = () => {

    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [columns, setColumns] = useState([]);
    const filters = [];

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle('Poslovna Godina'));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        getAllBusinessYears({...filter}).then(response => {
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            })
        })
    }

    return <TablePageContext.Provider value={value}>

        <TablePage onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteBusinessYear}
                   tableData={tableData} filter={filter}
                   handleEdit={handleEdit} filters={filters}
                   onCancel={() => onCancel()}
                   editPage={<EditBusinessYear data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddBusinessYear onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default BusinessYearList;