import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults, TablePageOptionNoEdit
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import AddBill from "./AddBill";
import {deleteBusinessPartner, getAllBusinessPartners} from "../../Services/BusinessPartner/BusinessPartnerService";
import {getAllProgramPositions} from "../../Services/ProgramPosition/ProgramPositionService";
import {deleteBill, generatePdf, getAllBills} from "../../Services/Bill/BillService";
import {useNavigate} from "react-router-dom";


const tableDescription = [

    { field: 'service_code', headerName: 'Šifra stavke', width: 200,
        renderCell : params => params.row.service.service_code },
    { field: 'year', headerName: 'Godina', width: 200,
        renderCell : params => params.row.business_year.year},
    { field: 'program_position', headerName: 'Programska pozicija', width: 200,
        renderCell : params => params.row.program_position.name },
];

const BillList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [filterTwo, setFilterTwo] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [columns, setColumns] = useState([]);
    const [programPositions, setProgramPosition] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionNoEdit);
    const filters = [];

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle('Obračunski list'));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        setTimeout(()=> {
            fetch();
            setPageState(PageState.View);
        }, 1000)
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const handleItem = (params) => {
        navigate('/bill-items', {
            state : {
                id : params.id
            }
        });
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        getAllBills({...filter}).then(response => {
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            })
        })

        getAllProgramPositions({...filterTwo}).then(response => {
            setProgramPosition(
                response.data.result
            )
        })
    }

    return <TablePageContext.Provider value={value}>

        <TablePage exportPdf={generatePdf} showPdf={true} showPureItems={true} hideEdit={true} onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteBill}
                   tableData={tableData} filter={filter}
                   filters={filters} handleItems={handleItem}
                   onCancel={() => onCancel()}
                   addPage={<AddBill programPositions={programPositions} onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default BillList;