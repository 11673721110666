import {createSlice} from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: undefined,
        permissions: [],
        company: undefined
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload
        },
        setAuth: (state, action) => {
            state.user = action.payload
            state.permissions = action.payload ? action.payload.permissions : null
            state.company = action.payload ? action.payload.company : null
        }
    }
});

export const { setUser } = authSlice.actions
export const { setPermissions } = authSlice.actions
export const { setAuth } = authSlice.actions

export default authSlice.reducer
