import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import TablePageContext, {
    FilterDefaults, FilterDefaultsMax,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import TablePage from "../../Components/DataGrid/TablePage";
import PageState from "../../Constants/Base/PageState";
import {addOfferItem, deleteOfferItem, editOfferItem, getAllOfferItems} from "../../Services/Offer/OfferItemService";
import {getAllServices} from "../../Services/Service/ServiceService";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import EditOfferItem from "./EditInvoiceItem";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import AddInvoiceItem from "./AddInvoiceItem";
import EditInvoice from "./EditInvoice";
import EditInvoiceItem from "./EditInvoiceItem";
import {
    deleteAdditionalInvoiceItem,
    deleteInvoiceItem,
    getAdditionalInvoiceItems,
    getInvoiceItems
} from "../../Services/Invoice/InvoiceItemService";
import ServiceType from "../../Constants/ServiceType";
import {generateOfferPdf} from "../../Services/Offer/OfferService";
import {generateInvoicePdf} from "../../Services/Invoice/InvoiceService";
import {numberWithCommas} from "../../Util/RenderDataUtil";
import EditAdditionalInvoiceItem from "./EditAdditionalInvoiceItem";


const tableDescription = [

    { field: 'counter', headerName: 'Redni broj', width: 200,
        renderCell : params => renderNumberRow(params) },
    { field: 'service_code', headerName: 'Stavka iz cenovnika', width: 200,
        renderCell : (params) => renderServiceCodeField(params) },
    { field: 'service', headerName: 'Opis Usluge', width: 200,
        renderCell : (params) => renderServiceField(params) },
    { field: 'unit_of_measure', headerName: 'Jedinica Mere', width: 200,
        renderCell : (params) => renderUnit(params) },
    { field: 'count', headerName: 'Količina', width: 200 },
    { field: 'price_without_pdv', headerName: 'Cena bez Pdv-a', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_without_pdv.toFixed(2)) },
    { field: 'price_with_pdv', headerName: 'Cena sa Pdv-om', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price_with_pdv.toFixed(2)) },
    { field: 'pdv', headerName: 'Pdv', width: 200,
        renderCell : (params) => numberWithCommas(params.row.pdv.toFixed(2)) },
    { field: 'pdv_value', headerName: 'Iznos Pdv-a', width: 200,
        renderCell : (params) => numberWithCommas(params.row.pdv_value.toFixed(2)) },
    { field: 'all_price_without_pdv', headerName: 'Ukupno bez Pdv-a', width: 200,
        renderCell : (params) => numberWithCommas(params.row.all_price_without_pdv.toFixed(2)) },
    { field: 'all_price_with_pdv', headerName: 'Ukupno sa Pdv-om', width: 200,
        renderCell : (params) => numberWithCommas(params.row.all_price_with_pdv.toFixed(2)) },
];

function renderNumberRow(params){
    return params.row.counter;
}

function renderUnit(params){
    return params.row.service.unit_of_measure;
}

function renderServiceField(params){
    return params.value.description;
}
function renderServiceCodeField(params){
    return params.row.service.service_code;
}
const filters = [];

const AdditionalInvoiceItemList = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const id = location.state ? location.state.id : null;
    const [showLoader, setShowLoader] = useState(false);
    const [pageState, setPageState] = useState(PageState.View);
    const [services, setServices] = useState([]);
    const [items, setItems] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [filterItems, setFilterItems] = useState(FilterDefaultsMax);
    const [filterItemsTwo, setFilterItemsTwo] = useState(FilterDefaultsMax);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [columns, setColumns] = useState([]);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        fetch();
        setColumns([...tableDescription]);

        dispatch(changeHeaderTitle('Dodatne Stavke Fakture'));

    }, [])

    const onFinish = () => {
        setTimeout(()=> {
            fetch();
            setPageState(PageState.View);
        }, 1000)
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        filterItems.type = ServiceType[0].value;
        filterItems.type2 = ServiceType[2].value;

        getAllServices({...filterItems}).then(response => {
            setServices(response.data.result);
        })

        filterItemsTwo.type = ServiceType[1].value;
        filterItemsTwo.type2 = null;

        getAllServices({...filterItemsTwo}).then(response => {
            setItems(response.data.result);
        })

        getAdditionalInvoiceItems({
            ...filter,
            invoice : id
        }).then(response => {
            let result = response.data.result;

            let counter = 1;

            result.forEach(item => {
                item.counter = counter;
                counter++;
            })

            setTableData({
                loading: false,
                data: result,
                total: response.data.total
            })
        })


        setTableData({
            ...tableData,
            loading: false
        });
    }

    useEffect(() => {
        fetch();
    }, [filter])

    const showMessage = (message, type) => {
        setAlertType(type);
        setMessageSnackbar(message)
        setShowSnackbar(true);
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <TablePageContext.Provider value={value}>
                <TablePage hideButton={true} onFinish={() => onFinish()}  tableDescription={columns}
                           deleteItem={deleteAdditionalInvoiceItem}
                           tableData={tableData} filter={filter}
                           handleEdit={handleEdit} filters={filters}
                           editPage={<EditAdditionalInvoiceItem items={items} data={ selectedItems } services={services} onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                />
            </TablePageContext.Provider>
        </Paper>
    </div>
}

export default AdditionalInvoiceItemList;