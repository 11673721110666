import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";



const LoginForm = ({
                       onSubmit,
                       form,
                       errors,
                       formRules
                   }) => (
    <FormProvider {...form}>
        <form id='login-form' className='form' action="#">

            <TextFieldControl
                name='username'
                defaultValue=''
                rules={formRules['username']}
                fullWidth
                margin="normal"
                error={Boolean(errors.username)}
                helperText={errors.username && strings.forms.login.usernameError}
                label={strings.forms.login.username}
            />

            <TextFieldControl
                name='password'
                defaultValue=''
                rules={formRules['password']}
                fullWidth
                type='password'
                margin="normal"
                error={Boolean(errors.password)}
                helperText={errors.password && strings.forms.login.passwordError}
                label={strings.forms.login.password}
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.login.login}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default LoginForm;
