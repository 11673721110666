import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";


const BusinessYearListForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">

            <TextFieldControl
                name='year'
                rules={formRules['year']}
                control={data}
                error={Boolean(errors.year)}
                helperText={errors.year && strings.forms.common.thisFieldIsRequired}
                label={'Godina'}
            />

            <TextFieldControl
                name='avans'
                rules={formRules['avans']}
                control={data}
                error={Boolean(errors.avans)}
                helperText={errors.avans && strings.forms.common.thisFieldIsRequired}
                label={'Avans'}
            />

            {/*<TextFieldControl*/}
            {/*    name='current_avans'*/}
            {/*    rules={formRules['current_avans']}*/}
            {/*    control={data}*/}
            {/*    error={Boolean(errors.current_avans)}*/}
            {/*    helperText={errors.current_avans && strings.forms.common.thisFieldIsRequired}*/}
            {/*    label={'Current Avans'}*/}
            {/*/>*/}

            {/*<TextFieldControl*/}
            {/*    name='discount_avans'*/}
            {/*    rules={formRules['discount_avans']}*/}
            {/*    control={data}*/}
            {/*    error={Boolean(errors.discount_avans)}*/}
            {/*    helperText={errors.discount_avans && strings.forms.common.thisFieldIsRequired}*/}
            {/*    label={'Discount Avans'}*/}
            {/*/>*/}


            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default BusinessYearListForm;