import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import ServiceType from "../../../../Constants/ServiceType";
import DateTimeControl from "../../../Controls/Inputs/DateTimeControl";


const AddProvidersForm = ({
                                onSubmit,
                                onCancel,
                                programPositions,
                                data,
                                form,
                                users,
                                maker,
                                oversight,
                                errors,
                              selectedItems,
                                values,
                                setValue,
                                partner,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">

            {console.log(selectedItems)}
            <SelectControl
                defaultValue={selectedItems.length > 0 ? selectedItems[0].maker : {}}
                setValue={setValue}
                name='maker'
                error={Boolean(errors.maker)}
                rules={formRules['maker']}
                label={'Specifikaciju izradio/la'}
                options={users}
                nameKey={'name'}
                valueKey={'id'}
            />
            <SelectControl
                defaultValue={selectedItems.length > 0 ? selectedItems[0].oversight : {}}
                setValue={setValue}
                name='oversight'
                error={Boolean(errors.oversight)}
                rules={formRules['oversight']}
                label={'Saglasan strucni nadzor'}
                options={users}
                nameKey={'name'}
                valueKey={'id'}
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default AddProvidersForm;