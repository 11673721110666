import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllUsers(data) {
    return await request('/api/users/all', data, HttpMethod.GET);
}

export async function activateUser(token) {
    return await request('/api/users/activate/' + token, {}, HttpMethod.PATCH);
}

export async function editUser(data) {
    return await request('/api/admin/users', data, HttpMethod.PUT);
}

export async function addUser(data) {
    return await request('/api/admin/users', data, HttpMethod.POST);
}

export async function deleteUser(id) {
    return await request('/api/users/' + id, [], HttpMethod.DELETE);
}

export async function changePassword(data) {
    return await request('/api/users/change-password' , data, HttpMethod.PUT);
}