import {TextField} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {DateTimePicker, DesktopDatePicker} from "@mui/lab";
const DateControl = ({data, value, setValue, size, label, name}) => {

    const [date, setDate] = useState(value);

    useEffect(() => {
        setDate(value)
    }, [value])

    return <Controller
        control={data}
        render={({ field }) =>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label={label}
                    value={date}
                    onChange={(newValue) => {
                        setValue(name, newValue);
                        setDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        size={size ? size : 'small'}
                    />}/>
            </LocalizationProvider>}>

    </Controller>

}

export default DateControl;