import React from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";


const UserListForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                            }) => (
    <FormProvider {...form}>
        <form id='product-service-form' className='form' action="#">

            <TextFieldControl
                name='name'
                rules={formRules['name']}
                control={data}
                error={Boolean(errors.name)}
                helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                label={'Ime'}
            />

            <TextFieldControl
                name='username'
                rules={formRules['username']}
                control={data}
                error={Boolean(errors.username)}
                helperText={errors.username && strings.forms.common.thisFieldIsRequired}
                label={'Username'}
            />

            <TextFieldControl
                name='visible_password'
                rules={formRules['visible_password']}
                control={data}
                error={Boolean(errors.visible_password)}
                helperText={errors.visible_password && strings.forms.common.thisFieldIsRequired}
                label={'Password'}
            />

            <CheckBoxControl
                name={'admin'}
                control={data}
                label={'Admin'}
                value={values['admin']}
                setValue={setValue}
            />


            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default UserListForm;