import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllBillItems(data) {
    return await request('/api/bill_items/all', data, HttpMethod.GET);
}

export async function editBillItem(data) {
    return await request('/api/bill_items', data, HttpMethod.PUT);
}

export async function addBillItem(data) {
    return await request('/api/bill_items', data, HttpMethod.POST);
}

export async function deleteBillItem(id) {
    return await request('/api/bill_items/' + id, [], HttpMethod.DELETE);
}