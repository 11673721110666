import * as React from 'react';
import {useState} from "react";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import strings from "../../localization";
import MoreVert from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FeedIcon from '@mui/icons-material/Feed';
const ActionCell = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlerClick = (handler) => {
        handleClose();
        handler(props.id);
    }

    return <React.Fragment>
        <IconButton size='small' onClick={(event) => handleClick(event)}
                    aria-controls={"basic-menu-" + props.id} aria-haspopup="true">
            <MoreVert/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            id={"basic-menu-" + props.id}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
        >
            {
                props.tablePageOptions.showActionView && props.showInvoices &&
                <MenuItem onClick={() => handlerClick(props.handleInvoice)}>
                    <FeedIcon>
                        <RemoveRedEyeIcon/>
                    </FeedIcon>
                    <ListItemText>Kreiraj fakturu</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showPdf &&
                <MenuItem onClick={() => handlerClick(props.exportPdf)}>
                    <FeedIcon>
                        <RemoveRedEyeIcon/>
                    </FeedIcon>
                    <ListItemText>Eksportuj PDF</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showContracts &&
                <MenuItem onClick={() => handlerClick(props.handleContracts)}>
                    <FeedIcon>
                        <RemoveRedEyeIcon/>
                    </FeedIcon>
                    <ListItemText>Ugovori ponude</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showItems &&
                <MenuItem onClick={() => handlerClick(props.handleItems)}>
                    <ListItemIcon>
                        <RemoveRedEyeIcon/>
                    </ListItemIcon>
                    <ListItemText>Stavke ponude</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showPureItems &&
                <MenuItem onClick={() => handlerClick(props.handleItems)}>
                    <ListItemIcon>
                        <RemoveRedEyeIcon/>
                    </ListItemIcon>
                    <ListItemText>Po mesecima</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showGetXmlItems &&
                <MenuItem onClick={() => handlerClick(props.handleGetXml)}>
                    <ListItemIcon>
                        <RemoveRedEyeIcon/>
                    </ListItemIcon>
                    <ListItemText>Skini XML</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showInvoiceItems &&
                <MenuItem onClick={() => handlerClick(props.handleItems)}>
                    <ListItemIcon>
                        <RemoveRedEyeIcon/>
                    </ListItemIcon>
                    <ListItemText>Stavke fakture</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionView && props.showAdditionalInvoiceItems &&
                <MenuItem onClick={() => handlerClick(props.handleAddItems)}>
                    <ListItemIcon>
                        <RemoveRedEyeIcon/>
                    </ListItemIcon>
                    <ListItemText>Dodatne Stavke fakture</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionEdit &&
                <MenuItem onClick={() => handlerClick(props.handleEdit)}>
                    <ListItemIcon>
                        <EditIcon/>
                    </ListItemIcon>
                    <ListItemText>{strings.components.actionCell.edit}</ListItemText>
                </MenuItem>
            }
            {
                props.tablePageOptions.showActionDelete &&
                <MenuItem onClick={() => handlerClick(props.handleDelete)}>
                    <ListItemIcon>
                        <DeleteIcon/>
                    </ListItemIcon>
                    <ListItemText>{strings.components.actionCell.delete}</ListItemText>
                </MenuItem>
            }
        </Menu>
    </React.Fragment>


}

export default ActionCell;