import TextField from "@mui/material/TextField";
import {Controller} from "react-hook-form";
import React from "react";

const TextFieldControl = (props) => {
    return <Controller
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field }) =>
            props.onChange ?
            <TextField {...field}
                        onChange={props.onChange}
                        multiline={props.multiline ? props.multiline : false}
                       disabled={props.disabled}
                       size={props.size ? props.size : 'small'}
                       fullWidth={props.fullWidth ? props.fullWidth : true}
                       type={props.type}
                       margin={props.margin ? props.margin : 'normal'}
                       error={props.error}
                       helperText={props.helperText}
                       label={props.label}
            />
            :
            <TextField {...field}
                       multiline={props.multiline ? props.multiline : false}
                       disabled={props.disabled}
                       size={props.size ? props.size : 'small'}
                       fullWidth={props.fullWidth ? props.fullWidth : true}
                       type={props.type}
                       margin={props.margin ? props.margin : 'normal'}
                       error={props.error}
                       helperText={props.helperText}
                       label={props.label}
            />
        }
    />
}

export default TextFieldControl;