import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllProgramPositions(data) {
    return await request('/api/program_positions/all', data, HttpMethod.GET);
}

export async function editProgramPosition(data) {
    return await request('/api/program_positions', data, HttpMethod.PUT);
}

export async function addProgramPosition(data) {
    return await request('/api/program_positions', data, HttpMethod.POST);
}

export async function deleteProgramPosition(id) {
    return await request('/api/program_positions/' + id, [], HttpMethod.DELETE);
}