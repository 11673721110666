import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllOfferContracts(data) {
    return await request('/api/offer_contracts/all', data, HttpMethod.GET);
}

export async function editOfferContract(data) {
    return await request('/api/offer_contracts', data, HttpMethod.PUT);
}

export async function addOfferContract(data) {
    return await request('/api/offer_contracts', data, HttpMethod.POST);
}

export async function deleteOfferContract(id) {
    return await request('/api/offer_contracts/' + id, [], HttpMethod.DELETE);
}