import React, {useEffect} from "react";
import {changePageSizeState} from "../Slices/PageSlice";
import PageSizeState from "../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";

const Home = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
    }, [])

    return ''
};
export default Home;
