import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults, TablePageOptionYearFilter
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import EditOffer from "./EditOffer";
import AddOffer from "./AddOffer";
import {
    deleteOffer,
    generateOfferPdf,
    getAllOffers
} from "../../Services/Offer/OfferService";
import {getAllProgramPositions} from "../../Services/ProgramPosition/ProgramPositionService";
import {useNavigate} from "react-router-dom";
import {formatAMPM} from "../../Util/DateUtil";
import CreateInvoiceFromOfferForm from "../../Components/Forms/Pages/Offer/CreateInvoiceFromOfferForm";
import {useForm} from "react-hook-form";
import CreateInvoiceFromOffer from "./CreateInvoiceFromOffer";
import {numberWithCommas} from "../../Util/RenderDataUtil";

const tableDescription = [

    { field: 'counter', headerName: 'Redni broj', width: 200 },
    { field: 'offer_number', headerName: 'Broj ponude', width: 200 },
    { field: 'program_position', headerName: 'Programska Pozicija', width: 200,
        renderCell: (params) => renderProgramPosition(params)},
    { field: 'name', headerName: 'Naziv Ponude', width: 200 },
    { field: 'date', headerName: 'Datum', width: 200,
        renderCell : (params) => formatAMPM(new Date(Date.parse(params.value))) },
    { field: 'price', headerName: 'Ugovorena vrednost', width: 200,
        renderCell : (params) => numberWithCommas(params.row.price.toFixed(2)) },
    { field: 'paid_price', headerName: 'Plaćena vrednost', width: 200,
        renderCell : (params) => numberWithCommas(params.row.paid_price.toFixed(2)) },
    { field: 'additional_service_price', headerName: 'Ugovoreni dodatni radovi', width: 200,
        renderCell : (params) => numberWithCommas(params.row.additional_service_price.toFixed(2)) },
    { field: 'paid_additional_service_price', headerName: 'Plaćeni dodatni radovi', width: 200,
        renderCell : (params) => numberWithCommas(params.row.paid_additional_service_price.toFixed(2)) },

    { field: 'business_year', headerName: 'Poslovna Godina', width: 200,
        renderCell: (params) => renderBusinessYear(params) }
];

function renderBusinessYear(params){
    return params.value.year;
}

function renderProgramPosition(params){
    return params.value.name;
}
const filters = [];

const OfferList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [programPositions, setProgramPositions] = useState([]);
    const [offerId, setOfferId] = useState(null);
    const [businessYears, setBusinessYears] = useState([]);
    const [filter, setFilter] = useState(FilterDefaults);
    const [filterTwo, setFilterTwo] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionYearFilter);
    const [columns, setColumns] = useState([]);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter,filterTwo, setFilter, setFilterTwo, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.offer.pageTitle));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        // setTimeout(()=> {
            fetch();
        // }, 1000)
        setPageState(PageState.View);
    }

    const onFinish = () => {
        // setTimeout(()=> {
            fetch();
        // }, 1000)
        setPageState(PageState.View);
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const handleItems = (data) => {
        navigate('/offer-items', {
            state : {
                id : data.id
            }
        });
    }

    const handleContracts = (data) => {
        navigate('/offer-contracts', {
            state : {
                id : data.id
            }
        });
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        getAllOffers({...filter}).then(response => {
            let result = response.data.result;

            setTableData({
                loading: false,
                data: result,
                total: response.data.total
            })

            let counter = 1;

            result.forEach(item => {
                item.counter = counter;
                counter++;
            })
        })

        getAllProgramPositions({...filterTwo}).then(response => {
            setProgramPositions(response.data.result)
        })
    }

    const handleInvoice = (params) => {
        setPageState(PageState.OfferInvoice);
        setOfferId(params.id);
    }

    const exportPdf = (params) => {
        generateOfferPdf(params.id).then(response => {
            // if (!response.ok){
            //     return
            // }
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage showPdf={true} exportPdf={exportPdf} handleInvoice={handleInvoice} showInvoices={true} showItems={true} showContracts={true} onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteOffer}
                   tableData={tableData} filter={filter}
                   handleEdit={handleEdit} filters={filters}
                   onCancel={() => onCancel()}
                   handleItems={handleItems}
                   handleContracts={handleContracts}
                   addInvoicePage={<CreateInvoiceFromOffer offerId={offerId} data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   editPage={<EditOffer programPositions={programPositions} data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddOffer programPositions={programPositions} onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default OfferList;