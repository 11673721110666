import strings from '../localization';
import { format, parse } from 'date-fns'

export const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var month = date.getMonth() + 1;
    return date.getDate() + '.' + month + '.' + date.getFullYear();
}

export const getYears = (plusYears = 0) => {

    let result = [];
    let currentYear = (new Date()).getFullYear();

    currentYear += plusYears;

    for(let i = currentYear; i >= currentYear - 99; i--) {

        result.push({
            id: i,
            value: i
        });
    }

    return result;
}

export const getDaysOfMonth = (year, month) => {

    let date = new Date(year, month - 1, 1);
    let days = [];

    while (date.getMonth() === month - 1) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return days;
}

export const getMonths = () => {

    return [
        {id: 1, value: strings.months.january},
        {id: 2, value: strings.months.february},
        {id: 3, value: strings.months.march},
        {id: 4, value: strings.months.april},
        {id: 5, value: strings.months.may},
        {id: 6, value: strings.months.june},
        {id: 7, value: strings.months.july},
        {id: 8, value: strings.months.august},
        {id: 9, value: strings.months.september},
        {id: 10, value: strings.months.october},
        {id: 11, value: strings.months.november},
        {id: 12, value: strings.months.december},
    ];
}

export const leapYear = (year) => {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export const stringToDate = (date, formatString = 'DD-MM-YYYY') => {
    return parse(date, formatString, new Date());
}

export const dateToString = (date, formatString = 'dd-MM-yyyy') => {
    return format(date, formatString);
}

export const dateToStringDatePickerFormat = (date, formatString = 'MM/DD/YYYY') => {
    return format(date, formatString);
}

export const dateTimeToString = (date, formatString = 'DD-MM-YYYY HH:MM') => {
    return format(date, formatString);
}

export const getCurrentYear = () => {
    return new Date().getFullYear();
}

export const getCurrentMonth = () => {
    return new Date().getMonth() + 1;
}
