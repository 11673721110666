import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../Components/DataGrid/TablePage";
import strings from "../../localization";
import {changeHeaderTitle} from "../../Slices/HeaderSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../Context/TablePageContext";
import PageState from "../../Constants/Base/PageState";
import {deleteUser, getAllUsers} from "../../Services/User/UserService";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import Checkbox from "@mui/material/Checkbox";


const tableDescription = [

    { field: 'name', headerName: 'Ime', width: 200 },
    { field: 'username', headerName: 'Korisničko Ime', width: 200 },
    { field: 'visible_password', headerName: 'Šifra', width: 200 },
    { field: 'admin', headerName: 'Admin', width: 200,
        renderCell : (params) => renderAdmin(params) },
];

function renderAdmin(params){
    return <Checkbox
        checked={params.row.admin}
    />;
}

const UsersList = () => {

    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const filters = [];
    const [columns, setColumns] = useState([]);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeHeaderTitle(strings.pages.user.userList.pageTitle));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const handleEdit = () => {
        setPageState(PageState.Edit);
    }

    const fetch = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        getAllUsers({...filter}).then(response => {
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            })
        })
    }

    return <TablePageContext.Provider value={value}>

        <TablePage onFinish={() => onFinish()}  tableDescription={columns}
                   deleteItem={deleteUser}
                   tableData={tableData} filter={filter} filters={filters}
                   handleEdit={handleEdit}
                   onCancel={() => onCancel()}
                   editPage={<EditUser data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddUser onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
        />
    </TablePageContext.Provider>;
}

export default UsersList;